import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import loginModel from "models/login";
import Layout, { LayoutProps } from "components/Layout";
import { AuthForm } from "forms";
import { returnPath } from "utils";

interface LoginProps {
  base: LayoutProps["base"];
}

export default function Login(props: LoginProps) {
  const { base } = props;
  const { data: session, status } = useSession();
  const loading = status === "loading";
  const router = useRouter();

  if (session) router.push(returnPath(router.query));

  if (session || loading) return "Loading…";

  return (
    <Layout base={base}>
      <AuthForm />
    </Layout>
  );
}

export function getStaticProps() {
  return loginModel();
}
